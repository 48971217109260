.App {
    /* font-family: "EF_Diary"; */
    /* font-family: "Tenada"; */
    font-family : "CookieRun_Regular";
}


@font-face {
    /* font-family: EF_Diary;
    src: url(/src/assets/fonts/EF_Diary.ttf); */
    font-family: CookieRun_Regular;
    src: url(/src/assets/fonts/CookieRun_Regular.ttf);
    
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

.wrap {
    padding : 5px 0;
    background-color: #A8C0D6;
}

.wrap_input {
    padding-top: 20px;
    padding-bottom: 0 ;
    max-height: 130px;
    background-color: #A8C0D6;
}

.wrap .chat {
    display: flex;
    align-items: flex-start;
    padding: 20px;
}

.wrap .chat .icon {
    position: relative;
    overflow: hidden;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #eee;
}

.wrap .chat .icon img {
    max-width: 100%; /* 추가 */
  }

.wrap .chat .icon i {
    position: absolute;
    top: 10px;
    left: 50%;
    font-size: 2.5rem;
    color: #aaa;
    transform: translateX(-50%);
}

.wrap .chat .textbox {
    position: relative;
    display: inline-block;
    max-width: calc(100% - 70px);
    padding: 10px;
    margin-top: 7px;
    font-size: 10px;
    border-radius: 10px;
}

.wrap .chat .textbox::before {
    position: absolute;
    display: block;
    top: 0;
    font-size: 1.5rem;
}

.wrap .ch1 .textbox {
    margin-left: 20px;
    background-color: white;
}

.wrap .ch1 .textbox::before {
    left: -15px;
    content: "◀";
    color: white;
}

.wrap .ch2 {
    flex-direction: row-reverse;
}

.wrap .ch2 .textbox {
    margin-right: 20px;
    background-color: #F9EB54;
}

.wrap .ch2 .textbox_type {
    margin-right: 20px;
    background-color: #F9EB54;
}

.wrap .ch2 .textbox::before {
    right: -15px;
    content: "▶";
    color: #F9EB54;
}

#send-container {
    display: flex;
    flex-basis: 10%;
    width: 100%;
    min-height: 100px;
    padding: 10px;
  }
  
  #send-container input,
  #send-container button {
    height: 100%;
  }
  
  #send-container input {
    flex-basis: 90%;
  }
  #send-container button {
    flex-basis: 10%;
  }